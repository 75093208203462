import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "../components/artist.module.css"
import Footer from "../components/footer"

const About = ({ data }) => {
  return (

    <Layout>
      <SEO
        title="About"
        description="A summary about At Home Middle School, how it works, who it was built for and who built it."
        image="/logo.png"
        pathname="/about"
      />

      <>
        <div style={{ minHeight: "100vh", width: "100vw", "--bubble2": "rgba(59, 181, 207, .36)" }}
             className={styles.bubbles}>
          <section className={styles.sectioncontent}>
            <div className={styles.artistname} data-sal="slide-up" data-sal-duration="1600">Supporting Your Homeschool
              Journey
            </div>
            <div className={styles.about}>
              <p className={styles.pararesources} data-sal="slide-up" data-sal-duration="1600">Understanding the
                challenges you face juggling work, family, and homeschooling, At Home Middle School is dedicated to
                providing a free, comprehensive curriculum to support your child's educational journey.</p>

              <p className={styles.pararesources} data-sal="slide-up" data-sal-duration="1600">We've curated the best
                free online lessons from trusted sources like TedEd, CK-12, and Khan Academy, covering all core
                subjects. </p>

              <p className={styles.pararesources} data-sal="slide-up" data-sal-duration="1600">
                <strong>Organized Daily Schedules </strong><br/>
                Eliminate the planning stress with our structured daily lessons. Each day's activities are clearly
                outlined, saving you valuable time.</p>

              <p className={styles.pararesources} data-sal="slide-up" data-sal-duration="1600">
                <strong>Flexible Learning Approach </strong><br/>
                Each lesson is designed to be completed within 30-60 minutes, allowing you to adapt the schedule to your
                child's individual needs and interests.
              </p>
              <p className={styles.pararesources} data-sal="slide-up" data-sal-duration="1600">
                <strong>Empowerment Through Learning </strong><br/>
                By providing a well-structured curriculum, we allow you to focus on what matters most – fostering your
                child's love of learning.
              </p>
              <p className={styles.pararesources} data-sal="slide-up" data-sal-duration="1600">
                <strong>Free and Accessible </strong><br/>
                We firmly believe in making education accessible to all. At Home Middle School is entirely free to use,
                with no ads, registration or hidden costs.
              </p>

              <p className={styles.pararesources} data-sal="slide-up" data-sal-duration="1600">
                <strong>Your Child's Privacy Matters </strong><br/>
                We prioritize the privacy of your child's information. We do not collect or track any personal data.
                However, clearing cookies will erase saved work. We recommend exploring alternative saving options like
                copying and pasting work into a document to preserve progress.
              </p>
              <p className={styles.pararesources} data-sal="slide-up" data-sal-duration="1600">
                <strong>Developed by a Homeschooling Mom </strong><br/>
                At Home Middle School was created by Melissa Caldwell, a homeschooling mom, a web developer, and former
                business professor. Recognizing the challenges faced by parents, she designed this resource to make your
                homeschooling journey easier and more rewarding.
              </p>

              <p className={styles.pararesources} data-sal="slide-up" data-sal-duration="1600">
                <strong>Contact us </strong>if you have any suggestions for improvements, questions, or concerns at <a
                  href="mailto:athomemiddleschool@gmail.com">athomemiddleschool@gmail.com</a>.<br/>
              </p>
<Footer/>
            </div>

          </section>
        </div>
      </>


    </Layout>
  )
}

export default About